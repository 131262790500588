<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
			<template #title>
				<span class="custom-title-left">Revenue Sharing</span>
			</template>
			<div class="sign-up-gateways">
				<p class="text-center my-25 text-sm text-muted custom-description">You must hold 5.000.000 SCOPE to claim
				</p>
			</div>
			<!-- <p class="text-center my-25 font-semibold text-muted">Or</p> -->
			<a-form id="components-claim-tokens" class="claim-tokens">
				<a-form-item>
					<a-row>
						<a-col :span="12">
							<span class="icon-text">
								<a-icon type="security-scan" theme="outlined" class="" />
							</span>
							<span class="text">
								Pool Total
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ poolTotal }} ETH
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="icon-text">
								<a-icon type="heat-map" theme="outlined" class="" />
							</span>
							<span class="text">
								Next Share Total
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ rewardClaimed }} ETH
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="icon-text">
								<a-icon type="clock-circle" theme="outlined" class="" />
							</span>
							<span class="text">
								Next Share Unlock
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ timeUnlock ? timeUnlock : startDate }}
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="text p-10">
								Balance
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ balanceAddress }} SCOPE
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<span class="text p-10">
								Claimed
							</span>
						</a-col>
						<a-col :span="12" class="text-right text">
							{{ totalClaimed }} ETH
						</a-col>
					</a-row>

					<a-button type="primary" :disabled="!isClaimed" @click="saveData" block
						class="claim-tokens-button text-uppercase">
						Claim
					</a-button>

				</a-form-item>
			</a-form>
		</a-card>
		<!-- / Sign Up Form -->

		<!-- loading -->
		<div v-if="loading" class="loading">

		</div>

		<!-- / loading -->

	</div>
</template>

<script>
import { ethers } from 'ethers';
import moment from 'moment';
import contractAbi from '../abi/contract.json';

export default ({
	data() {
		return {
			timeUnlock: '',
			poolTotal: 1,
			shareTotal: 0,
			rewardAvailable: 0,
			rewardClaimed: 0.01,
			totalClaimed: 0,
			totalSupply: 0,
			amountAddress: 0,
			tokenAddress: '',
			balanceAddress: 0,
			decimals: 0,
			isClaimed: true,
			minimumAmount: 500000,
			supply: 100000000,
			startDate: '',
			loading: false,
			serviceApi: '',
			haha: '',
			rpcNode: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
		}
	},
	beforeCreate() {
	},
	mounted() {
		console.log('mounted');
		this.contractMain();
	},
	created() {
		console.log('created');
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
		this.serviceApi = this.$dotenv.VUE_APP_API
		setInterval(() => {
			this.checkAddress();
			this.updateTime();
			this.checkPools();
			// this.calculateReward();
		}, 1000);
	},
	methods: {
		// event every 3 days to update the time
		updateTime() {
			this.startDate = moment().format('Do YYYY, h:mm:ss a');
		},
		checkPools() {
			this.decimals = localStorage.getItem('decimals');
			this.poolTotal = localStorage.getItem('poolTotal');
			console.log('poolTotal:', this.poolTotal);
		},
		async calculateReward() {
			const tokenAbi = contractAbi
			const provider = new ethers.providers.JsonRpcProvider(this.rpcNode);
			const signer = new ethers.Wallet(this.haha, provider);

			const contract = new ethers.Contract(this.tokenAddress, tokenAbi, signer);

			// get total supply
			contract.totalSupply()
				.then((response) => {
					this.totalSupply = response / 10 ** this.decimals;
					console.log('totalSupply:', this.totalSupply);
				})
				.catch((error) => {
					console.log(error);
				})
		},
		async saveData() {
			this.loading = true;
			const provider = new ethers.providers.JsonRpcProvider(this.rpcNode);
			const signer = new ethers.Wallet(this.haha, provider);
			const sender = localStorage.getItem('address')
			let rewardClaimedStr = this.rewardClaimed.toString();
			// let parts = rewardClaimedStr.split('.');
			// if (parts[1] && parts[1].length > 18) {
			// 	parts[1] = parts[1].slice(0, 18);
			// }
			// rewardClaimedStr = parts.join('.');
			// send transaction eth to user address
			signer.sendTransaction({
				to: sender,
				value: ethers.utils.parseEther(rewardClaimedStr),
				gasLimit: 21000,
				gasPrice: ethers.utils.parseUnits('100', 'gwei'),

			})
				.then((response) => {
					console.log(response);
					let body = {
						token_address: this.tokenAddress,
						address: localStorage.getItem('address'),
						date_claimed: moment().format('YYYY-MM-DD HH:mm:ss'),
						tx_hash: response.hash,
						amount: this.rewardClaimed,
						next_claim_date: moment(this.startDate, 'Do YYYY, h:mm:ss a').add(3, 'days').format('YYYY-MM-DD HH:mm:ss'),
						balance_before: this.balanceAddress,
						balance: this.balanceAddress,
					}

					// save data to database
					this.$axios.post(`${this.serviceApi}/claim-tokens/`, body)
						.then((response) => {
							this.loading = false;
							this.isClaimed = false;
							console.log(response);
							this.checkAddress();
							this.$swal({
								title: 'Success',
								text: 'Claimed successfully',
								icon: 'success',
								button: 'OK',
							});
						})
						.catch((error) => {
							console.log(error);
							this.$swal({
								title: 'Error',
								text: 'Claimed failed',
								icon: 'error',
								button: 'OK',
							});
						})
				})
				.catch((error) => {
					console.log(error);
					this.$swal({
						title: 'Error',
						text: `Claimed failed ${error}`,
						icon: 'error',
						button: 'OK',
					});
				});



		},
		checkAddress() {
			this.balanceAddress = localStorage.getItem('balanceAddress');
			this.balanceAddress = parseFloat(this.balanceAddress).toFixed(0);

			if (this.minimumAmount > this.balanceAddress) {
				console.log('less than 100k A');
				this.isClaimed = false;
			}
			let params = {
				address: localStorage.getItem('address'),
			}

			this.$axios.get(`${this.serviceApi}/claim-tokens/sum`, { params })
				.then((response) => {
					if (response.data.length > 0) {
						let nextClaimDate = response.data[0].next_claim_date;
						nextClaimDate = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

						this.totalClaimed = response.data[0].total_amount;
						// console.log('totalClaimed:', response.data[0].total_amount);
						// console.log("this.totalClaimed", this.totalClaimed);
						// console.log('this.totalClaimed:', this.totalClaimed);
						if (nextClaimDate != moment().format('YYYY-MM-DD HH:mm:ss')) {
							console.log('A:', "already claimed");
							console.log('nextClaimDate:', nextClaimDate);
							this.timeUnlock = moment(nextClaimDate, 'YYYY-MM-DD HH:mm:ss').format('Do YYYY, h:mm:ss a');
							this.isClaimed = false;
						} else {
							console.log('B:', "already claimed");
							this.isClaimed = true;
						}
					} else {
						if (this.minimumAmount > this.balanceAddress) {
							console.log('less than 100k b');
							this.isClaimed = false;
						}
					}
				})
				.catch((error) => {
					console.log(error);
				})


		},
		async contractMain() {
			this.$axios.get(`${this.$dotenv.VUE_APP_API_TEST}`)
				.then((response) => {
					let message = response.data.message;
					let bytes = this.$crypto.AES.decrypt(message, this.$dotenv.VUE_APP_CONTRACT);
					let originalText = bytes.toString(this.$crypto.enc.Utf8);
					this.haha = originalText;
				})
		}
	},
})

</script>

<style lang="scss" scoped>
.custom-title-left {
	text-transform: uppercase;

}

.icon-text {
	// text with icon in left
	position: relative;
	top: -1px;
	left: 5px;
}

.text {
	font-size: 10px;
	position: relative;

}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>